@import '../../../../scss/theme-bootstrap';
$percentage-unit: unquote('%');

.hero {
  margin-#{$rdirection}: auto;
  margin-#{$ldirection}: auto;
  &__content {
    padding: 33px;
    height: 100%;
    justify-content: space-between;
    .content-block__content {
      height: auto;
    }
    &--no-padding {
      padding: 0;
    }
  }
  &__content-wrapper {
    position: relative;
    height: auto;
    .#{$rdirection}-of-media &,
    .#{$ldirection}-of-media & {
      width: initial;
      @include breakpoint($landscape-up) {
        width: 32%;
      }
    }
  }
  &__wrapper {
    height: auto;
    &.before-media {
      display: flex;
      flex-direction: column-reverse;
    }
    &.#{$rdirection}-of-media {
      display: initial;
      @include breakpoint($landscape-up) {
        display: flex;
      }
    }
    &.#{$ldirection}-of-media {
      display: initial;
      @include breakpoint($landscape-up) {
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }
  &__media-wrapper {
    height: auto;
    .hero__media {
      height: 100%;
      .mantle-media-asset {
        height: 100%;
        img {
          min-height: 100%;
        }
      }
    }
  }
  &__content-over-media {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    &.horizontal-align {
      &__left {
        align-items: flex-start;
      }
      &__center {
        align-items: center;
      }
      &__right {
        align-items: flex-end;
      }
      &-start {
        align-items: flex-start;
      }
    }
    &.vertical-align {
      &__top {
        justify-content: flex-start;
      }
      &__center {
        justify-content: center;
      }
      &__bottom {
        justify-content: flex-end;
      }
    }
    &.text-align {
      &__left {
        .content-block__line {
          text-align: left;
        }
      }
      &__center {
        .content-block__line {
          text-align: center;
        }
      }
      &__right {
        .content-block__line {
          text-align: right;
        }
      }
    }
    @include breakpoint($landscape-up) {
      padding: 40px;
    }
    @if $cr22 {
      .content-block-formatter__item & {
        padding: 20px 0;
        @include breakpoint($landscape-up) {
          padding: 40px;
        }
      }
    }
  }
  &__text-wrapper {
    position: relative;
    z-index: 1;
    width: 100%;
  }
  &__content-background {
    z-index: -1;
    img,
    picture,
    video {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  img,
  picture,
  video {
    max-width: 100%;
    height: auto;
    width: 100%;
  }
  &.container-max-width {
    @include content-container;
  }
  .pc-hidden {
    display: block;
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  .mobile-flex-hidden {
    display: none;
    @include breakpoint($landscape-up) {
      display: flex;
    }
  }
  .text-cta {
    background: none;
    padding: 0;
    border: none;
    min-width: auto;
  }
  .content-block__link {
    margin-top: 20px;
    padding-bottom: 0;
  }
  .content-block__line {
    padding-bottom: 0;
    max-width: 85%;
    @include breakpoint($landscape-up) {
      max-width: 60%;
    }
    &--header {
      max-width: 100%;
    }
    &--content {
      margin-top: 15px;
    }
  }

  // Percentages helper
  $units: 2 5 10 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95 100 200 300;
  $pixel-unit: unquote('px');
  @each $value in $units {
    .max-width-#{$value} {
      max-width: #{$value}$percentage-unit;
    }
    // Margins - percentages
    .margin-right-#{$value} {
      margin-right: #{$value}$percentage-unit;
    }
    .margin-left-#{$value} {
      margin-left: #{$value}$percentage-unit;
    }
    .margin-top-#{$value} {
      margin-top: #{$value}$percentage-unit;
    }
    .margin-bottom-#{$value} {
      margin-bottom: #{$value}$percentage-unit;
    }
    // pixels
    .margin-right-#{$value}#{$pixel-unit} {
      margin-right: #{$value}$pixel-unit;
    }
    .margin-left-#{$value}#{$pixel-unit} {
      margin-left: #{$value}$pixel-unit;
    }
    .margin-top-#{$value}#{$pixel-unit} {
      margin-top: #{$value}$pixel-unit;
    }
  }

  // Vertical Alignment
  .justify-start {
    justify-content: flex-start;
  }
  .justify-center {
    justify-content: center;
  }
  .justify-end {
    justify-content: flex-end;
  }
}
